<template>
  <d-remote-monitoring-monitorings-table
    :user="user"
    :role="role"
  />
</template>

<script>

export default {
  name: 'RemoteMonitoringMonitoringsTable',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    role() {
      return this.$store.state.Auth.role;
    },
  },
};
</script>
